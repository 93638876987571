<template>
  <PageCard
    pageName="FinTech Market Reports"
    :iconClass="['cuis-chart-pie', 'card-icon-font']"
  >
    <template slot="page_content">
      <b-row class="about-row">
        <b-col cols="4" class="px-1">
          <b-card
            class="std-border mb-0"
            header-bg-variant="success"
            header-text-variant="white"
          >
            <div slot="header">FinTech Market Reports</div>
            <b-card-body class="p-0">
              <div>
                Quickly assess market share rank by vendor or vendor-product
                combos. Change the basis of share from FI count to deposit
                accounts. Then filter the rankings by FI type, size, or other
                variables to produce strategic insight or marketing ammo. You
                can click any vendor’s client count to see a list of their
                current clients.
              </div>
              <b-card-img
                src="../../../../static/img/market_reports.png"
                class="rounded-0 my-2"
              />
              <div class="mb-2">
                The FinTech Market Reports cover many retail, business, and
                operating solutions ranging from online account opening to core
                processing.
              </div>
              <div class="mb-2">
                Covering over 100 U.S. banking vertical solutions and over
                11,000 financial institutions, FinTech Market reports provide
                decisioning context where it was previously non-existent.
              </div>
              <div class="about-footer">
                <div class="about-footer-content">
                  <a
                    href="mailto:info@fi-navigator.com"
                    class="btn btn-secondary"
                    >More Info</a
                  >
                </div>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </template>
  </PageCard>
</template>

<script>
// global
import { mapState } from 'vuex'
// layout
import PageCard from '@/modules/core/components/layouts/PageCard'

export default {
  name: 'AboutMarketReports',
  components: {
    PageCard
  },
  created() {
    if (!this.partnerSite) {
      document.title = 'FI Vertical Strategy'
    }
  },
  computed: {
    ...mapState({
      partnerSite: state => state.partnerSite
    })
  }
}
</script>

<style lang="scss" scoped></style>
